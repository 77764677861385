import React from "react"
import { Helmet } from "react-helmet"

//propriedades, benefícios nutricionais

function SEO({location, base_url, post_date, post_cat, post_cat_name, post_slug, post_slug_name, post_descp_kps, nutrinfo, description, lang, keywords, title, canonical, image}) {
	
	let Org = {
		"@type": "Organization",
		"@id": `${base_url}/#organization`,
		"name": "INFO Nutrientes",
		"url": `${base_url}`,
		"sameAs": [],
		"logo": {
			"@type": "ImageObject",
			"@id": `${base_url}/#logo`,
			"inLanguage": "pt-BR",
			"url": `${base_url}/static/infonutrientes-logo-social.png`,
			"width": 700,
			"height": 700,
			"caption": "INFO Nutrientes - tudo sobre os alimentos"
		},
		"image": {
			"@id": `${base_url}/#logo`
		}
	}

	let webSite = {
		"@type": "WebSite",
		"@id": `${base_url}/#website`,
		"url": `${base_url}/`,
		"name": "INFO Nutrientes",
		"description": "Tudo sobre a composição nutricional dos alimentos, calorias, carboidratos, proteínas, gorduras, vitaminas e sais minerais",
		"publisher": {
			"@id": `${base_url}/#organization`
		},
		"potentialAction": [
			{
				"@type": "SearchAction",
				"target": `${base_url}/pesquisar/?s={search_term_string}`,
				"query-input": "required name=search_term_string"
			}
		],
		"inLanguage": "pt-BR"
	}

	let webPage = {
		"@type": "WebPage",
		"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}/#webpage`,
		"url": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}`,
		"name": `${post_descp_kps}`, //post title, keyphrases, KPsynonyms, 600px max"
		"isPartOf": {
			"@id": `${base_url}/#website`
		},
		// "primaryImageOfPage": {
		// 	//"@id": "http://172.18.0.2:8080/2021/02/26/slug-as-proteinas-da-carne-keyphrase/#primaryimage"
		// },
		"datePublished": `${post_date}`,
		"dateModified": `${post_date}`,
		"description": `${description}`, //"as proteinas da carne keyphrase meta descri\u00e7\u00e3o 155 characters KPsynonyms, relatedkeywords, relatedsinonimos",
		"breadcrumb": {
			"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}/#breadcrumb`
		},
		"inLanguage": "pt-BR",
		"potentialAction": [
			{
				"@type": "ReadAction",
				"target": [
					`${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}`,
				]
			}
		]
	}

	let Bread = {
		"@type": "BreadcrumbList",
		"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}/#breadcrumb`,
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
					"@type": "WebPage",
					"@id": `${base_url}/`,
					"url": `${base_url}/`,
					"name": "Home"
				}
			}
		].concat(
			post_cat
			?
				[
					{
						"@type": "ListItem",
						"position": 2,
						"item": {
							"@type": "WebPage",
							"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat+"/" : ""}`,
							"url": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat+"/" : ""}`,
							"name": `${post_cat_name}`
						}
					}
				]
			:
				null
				
		).concat(
			post_slug
			?
				[
					{
						"@type": "ListItem",
						"position": 3,
						"item": {
							"@type": "WebPage",
							"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}`,
							"url": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}`,
							"name": `${post_slug_name}`
						}
					}
				]
			:
				null
		)
	}

	let Art = {
		"@type": "Article",
		"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}/#article`,
		"isPartOf": {
			"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}/#webpage`
		},
		"author": {
			"@id": `${base_url}/#/schema/person/138e93a7223428b17e9bf8d71b4c0db5`
		},
		"headline": `${post_slug_name}`,
		"datePublished": `${post_date}`,
		"dateModified": `${post_date}`,
		"mainEntityOfPage": {
			"@id": `${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}/#webpage`
		},
		"commentCount": 0,
		"publisher": {
			"@id": `${base_url}/#organization`
		},
		"image": `${base_url}/static/${post_cat}.jpg`,
		"articleSection": `${post_cat_name}`,
		"inLanguage": "pt-BR",
		"potentialAction": [
			{
				"@type": "CommentAction",
				"name": "Comment",
				"target": [
					`${base_url}${post_cat ? post_cat === "/" ? post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}/#respond`
				]
			}
		]
	}

	let Person = {
		"@type": "Person",
		"@id": `${base_url}/#/schema/person/138e93a7223428b17e9bf8d71b4c0db5`,
		"name": "INFO Nutrientes",
		"image": {
			"@type": "ImageObject",
			"@id": `${base_url}/#personlogo`,
			"inLanguage": "pt-BR",
			"url": `${base_url}/static/infonutrientes-logo-social.png`,
			"caption": "INFO Nutrientes, tudo sobre a composição dos alimentos"
		},
		"sameAs": [
			"http:"
		],
	}

	// let Recp = {
	// 	"@type": "Recipe",
	// 	"author": "INFO Nutrientes",
	// 	"cookTime": "PT30M",
	// 	"datePublished": `${post_date}`,
	// 	"description": `${post_slug_name}`,
	// 	"image": `${base_url}/static/${cat_img}.jpg`,
	// 	"recipeIngredient": `${post_slug_name}`,
	// 	"name": `${post_slug_name}`,
	// 	"nutrition": {
	// 	  "@type": "NutritionInformation",
	// 	  "calories": `${nutrinfo.kcal} calorias por grama (kcal/g)`,
	// 	  "carbohydrateContent": `${nutrinfo.carbo} por grama (g/g)`,
	// 	  "proteinContent": `${nutrinfo.prot} por grama (g/g)`,
	// 	  "fatContent": `${nutrinfo.fat} por grama (g/g)`,
	// 	  "sodiumContent": `${nutrinfo.sal} por grama (g/g)`,
	// 	  "sugarContent": `${nutrinfo.acucar} por grama (g/g)`,
	// 	  "fiberContent": `${nutrinfo.fibra} por grama (g/g)`,
	// 	},
	// }


	let jsonld = {
		"@context": "https://schema.org",
		"@graph":	[
						Org,
						webSite,
						webPage,
						Bread,
					]
	}

	const mainPages = [
		"leite",
		"pescados",
		"frutas",
		"ovos",
		"cereais",
		"miscelanea",
		"vegetais",
		"carnes",
		"sementes",
		"gorduras",
		"leguminosas",
		"carboidratos",
		"proteinas",
		"gorduras-lipidios",
		"vitaminas",
		"sais-minerais",
		"calculo-nutricional"
	]

	// var lPath = false
	// var dPath = false
	// var slugPage = false 

	// try {

	const lPath = location.pathname.slice(1, location.pathname.length).split("/").filter(n => n).length
	const slugPage = location.pathname.slice(1, location.pathname.length).split("/").filter(n => n)[0]

	//console.log(location.pathname)

	// } catch{

	// 	lPath = location.pathname.split("/").length
	// 	dPath = location.pathname
	// 	slugPage = location.pathname.split("/")[1]

	// }

	if ( lPath < 2 ) {

		if (lPath === 0) {

			//console.log("index", props.path, props.path.split("/"), props.path.split("/").length)

		} else {
			if(mainPages.find(v => slugPage.includes(v))) {
				//console.log("page", props.path, props.path.split("/"), props.path.split("/").length)
				jsonld["@graph"].push(
					Art,
					Person,
				)
			} 

		}


	} else {

		//console.log("article", props.path, props.path.split("/"), props.path.split("/").length)
		jsonld["@graph"].push(
			Art,
			Person,
			{
				"@type": "Recipe",
				"author": "INFO Nutrientes",
				"cookTime": "PT30M",
				"datePublished": `${post_date}`,
				"description": `${post_slug_name}`,
				"image": `${base_url}/static/${post_cat}.jpg`,
				"recipeIngredient": `${post_slug_name}`,
				"name": `${post_slug_name}`,
				"nutrition": {
					"@type": "NutritionInformation",
					"calories": `${nutrinfo.kcal} calorias por grama (kcal/g)`,
					"carbohydrateContent": `${nutrinfo.carbo} gramas por grama (g/g)`,
					"proteinContent": `${nutrinfo.prot} gramas por grama (g/g)`,
					"fatContent": `${nutrinfo.fat} gramas por grama (g/g)`,
					"sodiumContent": `${nutrinfo.sal} miligramas por grama (mg/g)`,
					"sugarContent": `${nutrinfo.acucar} gramas por grama (g/g)`,
					"fiberContent": `${nutrinfo.fibra} gramas por grama (g/g)`,
				},
			},
		
		)
	}

	// console.log(image)

  	return (
		<Helmet
			htmlAttributes={
				{
					lang,
				}
			}

			title={title}

			link={
				[
					{
						rel: `canonical`,
						href: canonical
					}
				]
			}

			meta={
				[
					{
						name: `title`,
						content: title,

					},
					{
						name: `description`,
						content: description,
					},
					{
						name: `keywords`,
						content: keywords,
					},
					{
						property: `og:locale`,
						content: `pt_BR`
					},
					{
						property: `og:url`,
						content: `${base_url}${post_cat ? post_cat === "/" ? ""+post_cat : "/"+post_cat : ""}${post_slug ? "/"+post_slug : ""}`

					},
					{
						property: `og:title`,
						content: title

					},
					{
						property: `og:description`,
						content: description

					},
					{
						property: `og:type`,
						content: `${location.pathname === "/" ? "website" : "article" }`

					},
					{
						property: `og:image`,
						content: `${base_url}${image}`
					},
				].concat(
					location.pathname === "/" ?
						[
							{}
						]
					:
						[
							{
								property: `article:published_time`,
								content: `2021-03-15`
							},
							{
								property: `article:author`,
								content: `https://www.facebook.com/INFO-Nutrientes-103524061867422`
							},
							{
								property: `article:section`,
								content: `${post_cat ? post_cat === "/" ? "" : post_cat_name : ""}`
							}
						]
				)
			}
		>
			<script type="application/ld+json">
				{JSON.stringify(jsonld)}
			</script>

		</Helmet>
  	)
}
SEO.defaultProps = {
  lang: `pt-BR`,
  keywords: [],
  description: ``,
}
export default SEO