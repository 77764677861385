/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import "../css/style.css"
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme, createMuiTheme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import _theme from './theme.json'

const useStyles = makeStyles(theme => ({
	root: {
		//flexGrow: 1,
		//width: '100%',
		//zIndex: theme.zIndex.drawer + 1,
		display: 'flex',
		position: 'relative',
		background: 'rgba(31, 41, 55, 1)',
		padding: `${theme.spacing(6)}px 0 ${theme.spacing(6)}px 0`,
		//margin: `${theme.spacing(6)}px 0 0 0`,
		zIndex: theme.zIndex.drawer + 1,
	},

	logo: {
		//margin: 'auto ' +theme.spacing(1)+ 'px auto ' +theme.spacing(1)+ 'px',

		'& span': {
			fontSize: 26,
			fontWeight: 700,
			color: 'white',
			alignContent: 'center',
			textTransform: 'none',
			lineHeight: '26px',

		}
	},

	button: {
		fontSize: '12px',
		'&:hover': {
			border: '1px solid #000',
			borderRadius: '4px'
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '8px'
		},
	},
	'button .Mui-selected': {
		background: 'rgba(255,255,255,.2)',
		borderRadius: '4px'
	},
	list: {
		display: 'flex',
		flexDirection: 'row',
		padding: 0,
	},
	listitem: {
		width: 'auto',
	},
	listitemtext: {
		textAlign: 'center',
		'& span.MuiTypography-root': {
			fontWeight: 700,
			[theme.breakpoints.down('sm')]: {
				fontSize: '8px',
			},
			fontSize: '12px',
			color: '#fff',
			textTransform: 'uppercase'
		},
	},
	rippleClasses: {
		backgroundColor: 'rgba(255,255,255,.4)'

	},
}));

const grupos = [
    {    
        "slug":"leite",
        "name":"Leite"
    },
    {
        "slug":"pescados",
        "name":"Pescados"
    },
    {
        "slug":"frutas",
        "name":"Frutas"
    },
    {
        "slug":"ovos",
        "name":"Ovos"
    },
    {
        "slug":"cereais",
        "name":"Cereais"
    },
    {
        "slug":"miscelanea",
        "name":"Miscelânea"
    },
    {
        "slug":"vegetais",
        "name":"Vegetais"
    },
    {
        "slug":"carnes",
        "name":"Carnes"
    },
    {
        "slug":"sementes",
        "name":"Sementes"
    },
    {
        "slug":"gorduras",
        "name":"Gorduras"
    },
    {
        "slug":"leguminosas",
        "name":"Leguminosas"
    }
]

const macroCats = ['Proteinas', 'Carboidratos', 'Gorduras', 'Vitaminas', 'Sais Minerais']

const macrosPage = [
    {
        "slug":"carboidratos",
        "nome":"Carboidratos",
    },
    {
        "slug":"proteinas",
        "nome":"Proteínas",
    },
    {
        "slug":"gorduras-lipidios",
        "nome": "Gorduras (Lipídios)",
    },
    {
        "slug":"vitaminas",
        "nome":"Vitaminas",
    },
    {
        "slug":"sais-minerais", 
        "nome":"Sais Minerais",
    },
]

const Navigation = ({ children }) => {
	const classes = useStyles()
	const theme = useTheme()

	return (
		<>
			<AppBar component={'footer'} position="static" className={classes.root}>
				<Toolbar>
                    <Grid container direction="col" justify="center" alignContent="start" alignItems="center"
						  xs={12} sm={12} md={12} lg={12} xl={12}
					>
                        
                        <Grid item  xs={11} sm={11} md={3} lg={3} xl={3}>
                            <h3 className={"font-black text-3xl text-white mb-10 p-5 text-center bg-gray-700 rounded"}>
                                INFO Nutrientes
                            </h3>
                        </Grid>

                        <Grid item xs={11} sm={11} md={8} lg={8} xl={8}>

							<div class="w-full flex flex-col md:flex-row justify-between items-start">
								{/* Grupos Alime */}
								<div class="w-full flex flex-col justify-start items-center">
									
									<h3 class="w-11/12 text-center text-2xl font-normal text-gray-50 border-b pb-2 border-bg-gray-50">
										Grupos de Alimentos
									</h3>

									<div class="w-full flex flex-row justify-center items-start">
									
										<List>
										{
											grupos.slice(0,4).map((value, idx) => {
												return (
													<ListItem classes={{root:"hover:bg-gray-700 hover:rounded-xl"}}>
														<a href={`/${value.slug}/`} class="font-light text-gray-50">
															{value.name}
														</a>
													</ListItem>
												)
											})
										}
										</List>

										<List>
										{
											grupos.slice(4,8).map((value, idx) => {
												return (
													<ListItem classes={{root:"hover:bg-gray-700 hover:rounded-xl"}}>
														<a href={`/${value.slug}/`} class="font-light text-gray-50">
															{value.name}
														</a>
													</ListItem>
												)
											})
										}
										</List>

										<List>
										{
											grupos.slice(8,11).map((value, idx) => {
												return (
													<ListItem classes={{root:"hover:bg-gray-700 hover:rounded-xl"}}>
														<a href={`/${value.slug}/`} class="font-light text-gray-50">
															{value.name}
														</a>
													</ListItem>
												)
											})
										}
										</List>

									</div>
								</div>

								{/* MacroNutri */}
								<div class="w-full flex flex-col justify-start items-center">
									
									<h3 class="w-11/12 text-center text-2xl font-normal text-gray-50 border-b pb-2 border-bg-gray-50">
										Macronutrientes
									</h3>

									<div class="w-full flex flex-row justify-center items-start">
									
										<List>
										{
											macrosPage.slice(0,3).map((macro, idx) => {
												return (
													<ListItem classes={{root:"hover:bg-gray-700 hover:rounded-xl"}}>
														<a href={`/${macro.slug}/`} class="font-light text-gray-50">
															{macro.nome}
														</a>
													</ListItem>
												)
											})
										}
										</List>

										<List>
										{
											macrosPage.slice(3,5).map((macro, idx) => {
												return (
													<ListItem classes={{root:"hover:bg-gray-700 hover:rounded-xl"}}>
														<a href={`/${macro.slug}/`} class="font-light text-gray-50">
															{macro.nome}
														</a>
													</ListItem>
												)
											})
										}
										</List>

									</div>
								</div>
								
								{/* Calculadora */}
								<div class="w-full flex flex-col justify-start items-center">
									<h3 class="w-11/12 text-center text-2xl font-normal text-gray-50 border-b pb-2 border-bg-gray-50">
										Calculadora
									</h3>
									<div class="w-full flex flex-row justify-center items-start">
										<List>
											<ListItem classes={{root:"hover:bg-gray-700 hover:rounded-xl"}}>
												<a href="/calculo-nutricional-por-refeicao/" class="font-light text-gray-50">
													Monte sua refeição
												</a>
											</ListItem>
										</List>
									</div>

								</div>
								

							{/* main div */}
							</div>

                        </Grid>

                    </Grid>
				</Toolbar>
			</AppBar>
		</>

	)
}

export default Navigation;