/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import "../css/style.css"
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme, createMuiTheme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import _theme from './theme.json'
import ListSubheader from '@material-ui/core/ListSubheader'

const useStyles = makeStyles(theme => ({
	root: {
		//flexGrow: 1,
		//width: '100%',
		//zIndex: theme.zIndex.drawer + 1,
		display: 'flex',
		position: 'relative',
		background: 'rgba(0, 0, 0, 0.25)',
		padding: '0 0 0 0',
		//margin: `0 0 ${theme.spacing(2)}px 0`,
		zIndex: theme.zIndex.drawer + 1,
	},

	logo: {
		//margin: 'auto ' +theme.spacing(1)+ 'px auto ' +theme.spacing(1)+ 'px',

		'& span': {
			fontSize: 26,
			fontWeight: 700,
			color: 'white',
			alignContent: 'center',
			textTransform: 'none',
			lineHeight: '26px',

		}
	},

	button: {
		fontSize: '12px',
		'&:hover': {
			border: '1px solid #000',
			borderRadius: '4px'
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '8px'
		},
	},
	'button .Mui-selected': {
		background: 'rgba(255,255,255,.2)',
		borderRadius: '4px'
	},
	list: {
		display: 'flex',
		flexDirection: 'row',
		padding: 0,
	},
	listitem: {
		width: 'auto',
	},
	listitemtext: {
		textAlign: 'center',
		'& span.MuiTypography-root': {
			fontWeight: 700,
			[theme.breakpoints.down('sm')]: {
				fontSize: '8px',
			},
			fontSize: '12px',
			color: '#fff',
			textTransform: 'uppercase'
		},
	},
	rippleClasses: {
		backgroundColor: 'rgba(255,255,255,.4)'

	},
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
	},
    drawerPaper: {
        width: drawerWidth
    },
}));

const drawerWidth = 240;

function ListItemLink(props) {
	return <ListItem button component="a" {...props} />;
}

const Navigation = ({ children }) => {
	const classes = useStyles()
	const theme = useTheme()

	const Pages = [
		{
			"slug":"calculo-nutricional-por-refeicao",
			"nome":"Cálculo Nutricional",
		},
		{
			"slug":"carboidratos",
			"nome":"Carboidratos",
		},
		{
			"slug":"proteinas",
			"nome":"Proteínas",
		},
		{
			"slug":"gorduras-lipidios",
			"nome": "Gorduras",
		},
		{
			"slug":"vitaminas",
			"nome":"Vitaminas",
		},
		{
			"slug":"sais-minerais", 
			"nome":"Sais Minerais",
		},
	]

	const Grupos = [
		{    
			"slug":"leite",
			"name":"Leite"
		},
		{
			"slug":"pescados",
			"name":"Pescados"
		},
		{
			"slug":"frutas",
			"name":"Frutas"
		},
		{
			"slug":"ovos",
			"name":"Ovos"
		},
		{
			"slug":"cereais",
			"name":"Cereais"
		},
		{
			"slug":"miscelanea",
			"name":"Miscelânea"
		},
		{
			"slug":"vegetais",
			"name":"Vegetais"
		},
		{
			"slug":"carnes",
			"name":"Carnes"
		},
		{
			"slug":"sementes",
			"name":"Sementes"
		},
		{
			"slug":"gorduras",
			"name":"Gorduras"
		},
		{
			"slug":"leguminosas",
			"name":"Leguminosas"
		}
	]

	const [mobileOpen, setMobileOpen] = React.useState(false);

	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen)
	}
	const drawer = (
		<>
			<List>

				<ListItem button key={Pages[0].nome} component={"li"}>
					<a href={`/${Pages[0].slug}/`}>
						<ListItemText primary={Pages[0].nome} />
					</a>
				</ListItem>

				<ListSubheader component="div" id="nested-list-subheader">
					Macronutrientes
				</ListSubheader>

				{
					Pages.slice(1, 6).map((text, index) => (
						<ListItem button key={text.nome} component={"li"}>
							<a href={`/${text.slug}/`}>
								<ListItemText primary={text.nome} />
							</a>
						</ListItem>
					))
				}

				<ListSubheader component="div" id="nested-list-subheader">
					Alimentos
				</ListSubheader>

				{
					Grupos.map((text, index) => (
						<ListItem button key={text.name} component={"li"}>
							<a href={`/${text.slug}/`}>
								<ListItemText primary={text.name} />
							</a>
						</ListItem>
					))
				}


			</List>
		</>
	);

	return (
		<>
			<AppBar position="static" className={classes.root}>
				<Toolbar>
				<Grid container direction="row" justify="center" alignContent="center" alignItems="center" component={'nav'} xs={12} sm={12} md={12} lg={12} xl={12}>
					<Grid item  xs={10} sm={10} md={3} lg={3} xl={3}>
						<Button selected className={classes.logo}>
							<a href="/">
								INFO Nutrientes
							</a>
						</Button>
					</Grid>

					<Hidden mdUp implementation="css">
					<Grid item  xs={2} sm={2} style={{textAlign:'right'}}>
						<IconButton
							aria-label="Open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							style={{color:'white'}}
						>
							<MenuIcon />
						</IconButton>
					</Grid>
					</Hidden>

					<Hidden smDown implementation="css">
					<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
						<List className={classes.list} aria-label="navigation links">
						{
							Pages.map((item, index) => (
								<ListItemLink href={`/${item.slug}/`} className={classes.listitem} TouchRippleProps={{ classes: { child: classes.rippleClasses } }}>
									<ListItemText primary={item.nome} className={classes.listitemtext} />
								</ListItemLink>
							))
						}
						</List>
					</Grid>
					</Hidden>
				</Grid>
				</Toolbar>
			</AppBar>
			
			<nav className={classes.drawer}>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={'right'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<IconButton onClick={handleDrawerToggle}>
							<CloseIcon />
						</IconButton>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
		</>

	)
}

export default Navigation;