import { createMuiTheme } from '@material-ui/core/styles';
export const theme = createMuiTheme({
    overrides: { 
        MUIDataTableHeadCell: { 
            root: {
                minWidth: 200,
                //backgroundColor: "#fefefe",
                
                '&:first-child': { 
                    //width: 30,
                    //backgroundColor: "#FF0000", 
                    minWidth: 300,
                },

            },
            data: {
                fontWeight: 'bold',
            }

        },
        MUIDataTableBodyCell: {
            stackedHeader: {
                fontWeight: 'bold',
            }
        },
        MuiButtonBase: {
            root: {
                //marginRight: '0.75rem'
            }

        },
        MuiToggleButton : {
            root: {
                color: 'inherit',
            }
        }
    },
    "palette": {
        "common": {
            "black": "#000",
            "white": "#fff"
        },
        "background": {
            "paper": "#fff",
            "default": "#fafafa"
        },
        "primary": {
            "light": "#80e27e",
            "main": "#4caf50",
            "dark": "#087f23",
            "contrastText": "#000"
        },
        "secondary": {
            "light": "#4c8c4a",
            "main": "#1b5e20",
            "dark": "#003300",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
})